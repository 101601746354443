<template>
  <div class="identity">
    <div class="tit">{{ $t('reg_identity_1') }}！</div>
    <div class="identity-list flex-between">
      <div
        class="item"
        @click="identitySel(3)"
        :class="ruleForm.type === 3 ? 'active' : ''"
      >
        <img src="@/assets/img/overseas.png" />
        <div class="name">{{ $t('reg_identity_2') }}</div>
      </div>
      <div
        class="item"
        @click="identitySel(2)"
        :class="ruleForm.type === 2 ? 'active' : ''"
      >
        <img src="@/assets/img/organization.png" />
        <div class="name">{{ $t('reg_identity_3') }}</div>
      </div>
      <div
        class="item"
        @click="identitySel(1)"
        :class="ruleForm.type === 1 ? 'active' : ''"
      >
        <img src="@/assets/img/cn.png" />
        <div class="name">{{ $t('reg_identity_4') }}</div>
      </div>
    </div>
    <div class="textCenter">
      <div class="tips">{{ $t('reg_identity_5') }}</div>
      <el-button :loading="nextLoading" @click="next" type="primary">
        {{ $t('reg_identity_6') }}
      </el-button>
      <el-button @click="back" type="text">{{
        $t('reg_identity_7')
      }}</el-button>
      <!-- <div class="tip">{{ $t('已有账号?立即登录') }}</div> -->
    </div>
  </div>
</template>

<script>
import { getUrlArgs } from '@/utils/tool.js'
export default {
  name: 'Email',
  data() {
    return {
      ruleForm: {
        type: 1,
        step: this.$route.meta.sort,
        location_url: '',
      },
      isAgreement: false,
      nextLoading: false,
    }
  },
  created() {
    if (localStorage.getItem('sm_type')) {
      this.ruleForm.type = JSON.parse(localStorage.getItem('sm_type'))
    }
    if (getUrlArgs('email')) {
      localStorage.setItem('sm_email', getUrlArgs('email'))
      this.ruleForm.email = getUrlArgs('email')
    } else if (localStorage.getItem('sm_email')) {
      this.ruleForm.email = localStorage.getItem('sm_email')
    }
    if (getUrlArgs('invite_id')) {
      localStorage.setItem('invite_id', getUrlArgs('invite_id'))
      this.ruleForm.invite_id = getUrlArgs('invite_id')
    } else if (localStorage.getItem('invite_id')) {
      this.ruleForm.invite_id = localStorage.getItem('invite_id')
    } else {
      this.ruleForm.invite_id = ''
    }
  },
  methods: {
    identitySel(val) {
      this.ruleForm.type = val
    },
    back() {
      if (getUrlArgs('invite_id')) {
        this.$router.push({
          name: 'Email',
          params: { id: this.ruleForm.invite_id },
        })
      } else {
        this.$router.push({ name: 'Email' })
      }
    },
    async next() {
      // this.ruleForm.email = getUrlArgs('email')
      // this.ruleForm.invite_id = getUrlArgs('invite_id') || ''
      if (this.ruleForm.type === 1) {
        this.ruleForm.location_url = 'MainlandUser'
      } else if (this.ruleForm.type === 2) {
        this.ruleForm.location_url = 'OrganizationUser'
      } else if (this.ruleForm.type === 3) {
        this.ruleForm.location_url = 'OverseasUser'
      }
      this.nextLoading = true
      let res = await this.$axios.post('/api/User/register', this.ruleForm)
      this.nextLoading = false
      if (res.code === 1) {
        localStorage.setItem('sm_token', res.data.userinfo.token)
        localStorage.setItem('sm_email', this.ruleForm.email)
        localStorage.setItem('sm_type', this.ruleForm.type)
        localStorage.setItem('sm_userinfo', JSON.stringify(res.data.userinfo))
        if (this.ruleForm.type === 1) {
          this.$router.push({ name: 'MainlandUser' })
        } else if (this.ruleForm.type === 2) {
          this.$router.push({ name: 'OrganizationUser' })
        } else if (this.ruleForm.type === 3) {
          this.$router.push({ name: 'OverseasUser' })
        }
      }
    },
    // 验证邮箱
    async emsCheck() {
      let res = await this.$axios.post('/api/Ems/check', {
        email: getUrlArgs('email'),
        captcha: getUrlArgs('code'),
        event: 'register',
      })
      if (res.code != 1) {
        this.$router.push({ name: 'Login' })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.identity {
  width: 610px;
  margin: 60px auto 0;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 40px;
    text-align: center;
  }
}
.identity-list {
  .item {
    border-radius: 8px;
    border: 1px solid #999999;
    padding: 24px 16px;
    text-align: center;
    cursor: pointer;
    &.active,
    &:hover {
      border-color: #409eff;
    }
    img {
      width: 160px;
      height: 112px;
    }
    .name {
      font-size: 14px;
      color: #666666;
      margin-top: 10px;
    }
  }
}
.textCenter {
  .el-button {
    width: 100%;
    margin-left: 0;
  }
  .tips {
    font-size: 12px;
    color: #f56c6c;
    line-height: 12px;
    letter-spacing: 1px;
    margin: 20px 0 40px;
  }
  .tip {
    color: #409eff;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>
